var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: { icon: _vm.$route.meta.icon, title: _vm.$route.meta.title },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _vm.showActions ? _c("sys-calendar-pop-over") : _vm._e(),
              _vm.showActions
                ? _c("v-divider", { attrs: { vertical: "", inset: "" } })
                : _vm._e(),
              _vm.showActions
                ? _c("emc-excel-import-icon", {
                    attrs: { param: "event_calendars" },
                    on: {
                      onCompleted: function($event) {
                        return _vm.getData()
                      }
                    }
                  })
                : _vm._e(),
              _c("emc-excel-export-icon", {
                attrs: { "filter-calendar": "", "report-id": "16" }
              }),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        var attrs = ref.attrs
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "mx-1",
                                  attrs: {
                                    color: "indigo darken-1",
                                    icon: "",
                                    small: ""
                                  },
                                  on: {
                                    click: function($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.download(15)
                                    }
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c("v-icon", [
                                _vm._v("mdi-file-delimited-outline")
                              ])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ])
                },
                [_c("span", [_vm._v(" Relatório em Linhas ")])]
              ),
              _vm.showActions
                ? _c("emc-button-new-item", {
                    on: {
                      click: function($event) {
                        return _vm.addEvent()
                      }
                    }
                  })
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.progress } }),
      _c(
        "v-container",
        { staticClass: "pa-0 ma-0", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            { staticClass: "fill-height" },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-sheet",
                    { attrs: { height: "64" } },
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mr-4",
                              attrs: { outlined: "", color: "grey darken-2" },
                              on: { click: _vm.setToday }
                            },
                            [_vm._v(" Hoje ")]
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                text: "",
                                small: "",
                                color: "grey darken-2"
                              },
                              on: { click: _vm.prev }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-chevron-left ")
                              ])
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                fab: "",
                                text: "",
                                small: "",
                                color: "grey darken-2"
                              },
                              on: { click: _vm.next }
                            },
                            [
                              _c("v-icon", { attrs: { small: "" } }, [
                                _vm._v(" mdi-chevron-right ")
                              ])
                            ],
                            1
                          ),
                          _c("v-toolbar-title", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$refs.calendar
                                    ? _vm.$refs.calendar.title
                                    : _vm.titleDate
                                ) +
                                " "
                            )
                          ]),
                          _c("v-spacer"),
                          _c(
                            "v-menu",
                            {
                              attrs: { bottom: "", right: "" },
                              scopedSlots: _vm._u([
                                {
                                  key: "activator",
                                  fn: function(ref) {
                                    var on = ref.on
                                    var attrs = ref.attrs
                                    return [
                                      _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                outlined: "",
                                                color: "grey darken-2"
                                              }
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(_vm.typeToLabel[_vm.type])
                                            )
                                          ]),
                                          _c(
                                            "v-icon",
                                            { attrs: { right: "" } },
                                            [_vm._v(" mdi-menu-down ")]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  }
                                }
                              ])
                            },
                            [
                              _c(
                                "v-list",
                                [
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.type = "day"
                                        }
                                      }
                                    },
                                    [_c("v-list-item-title", [_vm._v("Dia")])],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.type = "week"
                                        }
                                      }
                                    },
                                    [
                                      _c("v-list-item-title", [
                                        _vm._v("Semana")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-list-item",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.type = "month"
                                        }
                                      }
                                    },
                                    [_c("v-list-item-title", [_vm._v("Mês")])],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "emc-util-scroll",
                    { attrs: { maxHeight: "450px" } },
                    [
                      _c(
                        "v-sheet",
                        { staticClass: "pa-1 ma-1" },
                        [
                          _vm.renderComponent
                            ? _c("v-calendar", {
                                ref: "calendar",
                                attrs: {
                                  color: "primary",
                                  events: _vm.events,
                                  "event-color": _vm.getEventColor,
                                  type: _vm.type,
                                  "interval-count": 0,
                                  "interval-height": 0
                                },
                                on: {
                                  "click:event": _vm.showEvent,
                                  "click:more": _vm.viewDay,
                                  "click:date": _vm.viewDay,
                                  change: _vm.updateRange
                                },
                                model: {
                                  value: _vm.focus,
                                  callback: function($$v) {
                                    _vm.focus = $$v
                                  },
                                  expression: "focus"
                                }
                              })
                            : _vm._e(),
                          _c(
                            "v-menu",
                            {
                              attrs: {
                                "close-on-content-click": false,
                                activator: _vm.selectedElement,
                                "offset-x": ""
                              },
                              model: {
                                value: _vm.selectedOpen,
                                callback: function($$v) {
                                  _vm.selectedOpen = $$v
                                },
                                expression: "selectedOpen"
                              }
                            },
                            [
                              _c(
                                "v-card",
                                {
                                  attrs: {
                                    color: "grey lighten-4",
                                    "min-width": "350px",
                                    flat: ""
                                  }
                                },
                                [
                                  _c(
                                    "v-toolbar",
                                    { attrs: { color: "indigo", dark: "" } },
                                    [
                                      _c("v-toolbar-title", {
                                        domProps: {
                                          innerHTML: _vm._s(
                                            _vm.selectedEvent.name
                                          )
                                        }
                                      }),
                                      _c("v-spacer"),
                                      _vm.showActions
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  return _vm.openNew()
                                                }
                                              }
                                            },
                                            [
                                              _c("v-icon", [
                                                _vm._v("mdi-pencil")
                                              ])
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.showActions
                                        ? _c(
                                            "v-btn",
                                            {
                                              attrs: { icon: "" },
                                              on: {
                                                click: function($event) {
                                                  $event.stopPropagation()
                                                  $event.preventDefault()
                                                  _vm.showDelete = true
                                                }
                                              }
                                            },
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { color: "error" } },
                                                [_vm._v("mdi-delete")]
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _vm.selectedEvent
                                    ? _c("v-card-text", [
                                        _vm._v(" Escopo: "),
                                        _vm.selectedEvent.event_scope
                                          ? _c("strong", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.selectedEvent.event_scope
                                                    .name
                                                )
                                              }
                                            })
                                          : _vm._e(),
                                        _c("br"),
                                        _vm._v(" Tipo: "),
                                        _vm.selectedEvent.event_type
                                          ? _c("strong", {
                                              domProps: {
                                                innerHTML: _vm._s(
                                                  _vm.selectedEvent.event_type
                                                    .name
                                                )
                                              }
                                            })
                                          : _vm._e(),
                                        _c("br"),
                                        _vm._v(" Letivo: "),
                                        _vm.selectedEvent
                                          ? _c("strong", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.selectedEvent.school_day
                                                    ? "Sim"
                                                    : "Não"
                                                )
                                              )
                                            ])
                                          : _vm._e(),
                                        _c("br"),
                                        _c("span", [
                                          _vm._v("Data: "),
                                          _vm.selectedEvent
                                            ? _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("date")(
                                                      _vm.selectedEvent
                                                        .date_start
                                                    )
                                                  )
                                                )
                                              ])
                                            : _vm._e(),
                                          _vm.selectedEvent.date_start !=
                                          _vm.selectedEvent.date_end
                                            ? _c("span", [
                                                _vm._v(" à "),
                                                _vm.selectedEvent
                                                  ? _c("strong", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm._f("date")(
                                                            _vm.selectedEvent
                                                              .date_end
                                                          )
                                                        )
                                                      )
                                                    ])
                                                  : _vm._e()
                                              ])
                                            : _vm._e()
                                        ]),
                                        _vm.selectedEvent.areas &&
                                        _vm.selectedEvent.areas.length > 0
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v("Área: "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getRelations(
                                                      _vm.selectedEvent.areas
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm.selectedEvent.publics &&
                                        _vm.selectedEvent.publics.length > 0
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v("Público: "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getRelations(
                                                      _vm.selectedEvent.publics
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm.selectedEvent.categories &&
                                        _vm.selectedEvent.categories.length > 0
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v("Categoria: "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getRelations(
                                                      _vm.selectedEvent
                                                        .categories
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm.selectedEvent.states &&
                                        _vm.selectedEvent.states.length > 0
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v("Estados: "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getRelations(
                                                      _vm.selectedEvent.states,
                                                      "initials"
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm.selectedEvent.cities &&
                                        _vm.selectedEvent.cities.length > 0
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v("Municípios: "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getRelations(
                                                      _vm.selectedEvent.cities
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm.selectedEvent.brands &&
                                        _vm.selectedEvent.brands.length > 0
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v("Marca: "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getRelations(
                                                      _vm.selectedEvent.brands,
                                                      "initials"
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm.selectedEvent.campuses &&
                                        _vm.selectedEvent.campuses.length > 0
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v("Campus: "),
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getRelations(
                                                      _vm.selectedEvent
                                                        .campuses,
                                                      "initials"
                                                    )
                                                  )
                                                )
                                              ])
                                            ])
                                          : _vm._e(),
                                        _vm.selectedEvent.details &&
                                        _vm.selectedEvent.details != ""
                                          ? _c("span", [
                                              _c("br"),
                                              _vm._v("Detalhes: "),
                                              _vm.selectedEvent
                                                ? _c("strong", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.selectedEvent
                                                          .details
                                                      )
                                                    )
                                                  ])
                                                : _vm._e()
                                            ])
                                          : _vm._e(),
                                        _vm.selectedEvent.fixed
                                          ? _c(
                                              "strong",
                                              [
                                                _c("br"),
                                                _c(
                                                  "v-icon",
                                                  {
                                                    attrs: {
                                                      color: "success",
                                                      small: ""
                                                    }
                                                  },
                                                  [_vm._v("mdi-pin-outline")]
                                                ),
                                                _vm._v(" Evento Fixo")
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ])
                                    : _vm._e(),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            text: "",
                                            color: "secondary"
                                          },
                                          on: {
                                            click: function($event) {
                                              _vm.selectedOpen = false
                                            }
                                          }
                                        },
                                        [_vm._v(" Fechar ")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("sys-register-event-calendar", {
        attrs: { model: _vm.eventSelectedOpen, show: _vm.selectedOpenNew },
        on: {
          onCompleted: function($event) {
            return _vm.getData()
          },
          "update:show": function($event) {
            _vm.selectedOpenNew = $event
          }
        }
      }),
      _c("emc-alert-modal", {
        attrs: {
          title: "Exclusão",
          text: "Deseja excluir o evento?",
          show: _vm.showDelete,
          disabled: _vm.loadingDelete
        },
        on: {
          "update:show": function($event) {
            _vm.showDelete = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.loadingDelete,
                      loading: _vm.loadingDelete,
                      color: "error"
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.deleteItem()
                      }
                    }
                  },
                  [
                    _vm._v("Excluir "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-delete")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }